/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:506680a2-4e6a-43ec-b31c-1ef6fe0a75dc",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_1MgY6gvAy",
    "aws_user_pools_web_client_id": "5rff1pt2bipvkb1fng16o5dv4p",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://fshvqjtegzhdpl62zw6bhzvcmu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-zl5r3hkfzre3rfwmgewe3eaxx4",
    "aws_cloud_logic_custom": [
        {
            "name": "registerlambda",
            "endpoint": "https://qhfzx7dtgi.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
