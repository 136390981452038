/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 * https://docs.amplify.aws/lib/analytics/autotrack/q/platform/js#page-view-tracking
 */

// You can delete this file if you're not using it
import Amplify from 'aws-amplify';
import config from './src/aws-exports';
// import Auth from '@aws-amplify/auth';

Amplify.configure(config);
